<template>
	<section>

		<Modal
			v-if="modalOpened"
			:overlay="true"
		>

			<ZadostKontrolaDialog
				v-if="modalDialog === 'kontrola'"
				:kontrola="kontrola"
				:kontrolaVysledek="kontrolaVysledek"
				@confirm="closeModal"
			/>

			<ZadostUzamceniPotvrzeniDialog
				v-if="modalDialog === 'uzamceni'"
				@confirm="kontrolaPredUzamcenim"
				@cancel="closeModal"
			/>

			<ZadostUzamceniReportDialog
				v-if="modalDialog === 'report'"
				@confirm="zavritUzamceniReport"
			/>

		</Modal>


		<Message type="error" class="mb-3"  v-if="error">
			{{ error }}
		</Message>

		<Loading class="mb-3" v-if="!dataLoaded" />
		<Loading class="mb-3"  title="Zamykání žádosti" v-if="isSaving" />

		<div class="buttons">
			<button
				class="button  button--outline"
				@click="zkontrolovatZadost"
			>
				<font-awesome-icon icon="clipboard-check" class="button__icon" />
				Zkontrolovat
			</button>


			<button
				class="button button--danger button--outline"
				@click="confirmUzamknoutZadost"
			>
				<font-awesome-icon icon="lock" class="button__icon" />
				Uzamknout žádost a odeslat ke schválení
			</button>

		</div>

	</section>
</template>

<script>
import ZadostiService from '@/services/ZadostiService'

import Modal from '@/components/Common/Modal'
import Loading from '@/components/Common/Loading'
import Message from '@/components/Common/Message'

import ZadostKontrolaDialog from '@/components/Zadost/ZadostKontrolaDialog'
import ZadostUzamceniPotvrzeniDialog from '@/components/Zadost/ZadostUzamceniPotvrzeniDialog'
import ZadostUzamceniReportDialog from '@/components/Zadost/ZadostUzamceniReportDialog'

export default {
	name: 'ZadostAkce',

	components: {
		Modal,
		Loading,
		Message,
		ZadostKontrolaDialog,
		ZadostUzamceniPotvrzeniDialog,
		ZadostUzamceniReportDialog,
	},

	props: {
		idZadosti: {
			type: Number,
			required: true,
		},
	},

	data() {
		return {
			modalOpened: false,
			modalDialog: '',

			error: '',
			dataLoaded: true,
			isSaving: false,

			projekty: [],
			kontrola: [],
			kontrolaVysledek: false,
		}
	},

	methods: {
		closeModal() {
			this.modalOpened = false
			this.modalDialog = ''
		},

		zkontrolovatZadost() {
			this.loadData(false)
		},

		otevritModalKontrola() {
			this.modalDialog = 'kontrola'
			this.modalOpened = true
		},


		otevritUzamceniReport() {
			this.modalDialog = 'report'
			this.modalOpened = true
		},

		zavritUzamceniReport() {
			this.closeModal()
			// refresh stránky, aby se projevilo zamčení žádosti
			this.$router.go(0)
		},

		confirmUzamknoutZadost() {
			this.modalDialog = 'uzamceni'
			this.modalOpened = true
		},


		kontrolaPredUzamcenim() {
			this.closeModal()
			// provede kontrolu žádosti a pokud je vše v pořádku
			// tak pokračuje na zamčení
			this.loadData(true)
		},


		uzamknoutZadost() {
			ZadostiService
			.zamknoutZadost(this.idZadosti)
			.then(response => {
				if (response.data.success) {
					this.otevritUzamceniReport()
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},



		// -------------------------------
		// -------------------------------
		// -------------------------------
		// KONTROLA ZADOSTI
		// -------------------------------
		// -------------------------------
		// -------------------------------

		kontrolaB1(typ, b1) {
			let chyby = []
			return chyby
		},

		kontrolaB2(typ, b2) {
			let chyby = []
			return chyby
		},

		kontrolaC(kontrola_c) {
			let chyby = []

			if (kontrola_c === undefined || kontrola_c === null || (Array.isArray(kontrola_c) && kontrola_c.length === 0)) {
				return chyby
			}

			kontrola_c.forEach(xlsChyba => {
				chyby.push(`Část ${xlsChyba.list}: chyba při importu XLSX, list ${xlsChyba.list} buňka ${xlsChyba.bunka}${xlsChyba.chyba ? ': ' + xlsChyba.chyba : ''}`)
			})

			return chyby
		},

		kontrolaProjektu(projekt) {
			let chyby = []

			chyby = chyby.concat( this.kontrolaB1(projekt.projekt.typ, projekt.b1) )
			chyby = chyby.concat( this.kontrolaB2(projekt.projekt.typ, projekt.b2) )
			chyby = chyby.concat( this.kontrolaC(projekt.projekt.kontrola_c) )

			const status = chyby.length === 0

			// ulozime stav projektu a pripadne chyby do seznamu zkontrolovaných projektů
			this.kontrola.push({
				id: projekt.projekt.id,
				kod: projekt.projekt.kod,
				nazev: projekt.projekt.nazev,
				status: status,
				chyby: chyby,
			})
		},


		kontrolaZadosti(pokracovatNaUzamceni) {
			this.kontrola = []
			this.kontrolaVysledek = false

			// zkontrolujeme, zda je v zadosti alespon jeden projekt
			if (this.projekty.length === 0) {
				this.kontrola.push({
					id: -1,
					kod: null,
					nazev: 'Žádost',
					status: false,
					chyby: [
						'Žádost musí obsahovat alespoň jeden projekt'
					]
				})
			}

			// projdeme vsechny projekty a kazdy zkontrolujeme
			this.projekty.forEach(projekt => {
				this.kontrolaProjektu(projekt)
			})

			// projdeme vsechny projekty a zjistime, zda je v nekterem chyba
			this.kontrolaVysledek = this.kontrola.reduce(
				(vysledek, projekt) => vysledek && projekt.status,
				true
			)

			// pokud jsou v kontrole chyby, vždy zobrazíme výsledek
			// kontroly a skončíme
			if (!this.kontrolaVysledek) {
				this.otevritModalKontrola()
				return
			}

			// je-li kontrola v pořádku a má se pokračovat na uzamčení
			// žádosti, tak pokračujeme
			// jinak zobrazíme výsledek kontroly (i když je vše v pořádku)
			if (pokracovatNaUzamceni) {
				this.uzamknoutZadost()
			} else {
				this.otevritModalKontrola()
			}

		},


		loadData(pokracovatNaUzamceni) {
			this.dataLoaded = false

			ZadostiService
			.getZadostProjekty(this.idZadosti)
			.then(response => {
				if (response.data.success) {
					this.projekty = response.data.data
					this.dataLoaded = true
					this.kontrolaZadosti(pokracovatNaUzamceni)
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				this.dataLoaded = true
			})
		},

	}
}
</script>
